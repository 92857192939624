<template>
  <div
    class="form container my-10"
  >
    <h2>{{ isEdit ? 'Редактировать' :'Добавить' }} перевозчика {{ isEdit ? `[${params.item.own_name}]` :'' }}</h2>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="form.own_name"
          label="Псевдоним"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="form.name"
          label="Наименование"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="form.inn"
          label="ИНН"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="form.country_letter"
          label="Страна"
          auto-select-first
          :items="countries"
          item-text="text"
          item-value="letterCode"
          :filter="filterBySearchField"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          ref="trade_country_region"
          v-model="form.region"
          label="Регион"
          auto-select-first
          :items="regionsForSelectedCountry"
          item-text="code"
          item-value="code"
          :filter="filterBySearchField"
          outlined
          dense
          background-color="white"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          :value="countryRegionName"
          disabled
          outlined
          dense
          background-color="#E1E1E1"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.city"
          label="Город"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.street"
          label="Улица"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="form.house"
          label="Дом"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="form.room"
          label="Помещение"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="form.postal_index"
          label="Почтовый индекс"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="phone"
          label="Телефон"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
    </v-row>
    <p class="mt-4">
      Филиал
    </p>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="form.branch_name"
          label="Наименовение"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="form.branch_inn"
          label="ИНН"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="form.branch_country_letter"
          label="Страна"
          auto-select-first
          :items="countries"
          item-text="text"
          item-value="letterCode"
          :filter="filterBySearchField"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          ref="trade_country_region"
          v-model="form.branch_region"
          label="Регион"
          auto-select-first
          :items="regionsForSelectedBranchCountry"
          item-text="code"
          item-value="code"
          :filter="filterBySearchField"
          outlined
          dense
          background-color="white"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          :value="branchCountryRegionName"
          disabled
          outlined
          dense
          background-color="#E1E1E1"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.branch_city"
          label="Город"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.branch_street"
          label="Улица"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="form.branch_house"
          label="Дом"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="form.branch_room"
          label="Помещение"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="form.branch_postal_index"
          label="Почтовый индекс"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="branch_phone"
          label="Телефон"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <div class="d-flex mt-4">
      <v-btn
        class="mr-4"
        color="#5CB7B1"
        dark
        @click="submit"
      >
        Сохранить
      </v-btn>
      <v-btn
        color="red"
        dark
        @click="clear"
      >
        Очистить
      </v-btn>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { 
  getCountryDigitCodeByLetterCode,
  getCountryNameByCode,
} from "@/helpers/catalogs";

export default {
  mixins:[filterBySearchFieldMixin],

  props:{
    params:{
      required:false,
      default:null,
      validator: (prop) => typeof prop === "object" || prop === null,
    }
  },
  data:() => ({
    phone:"",
    branch_phone:"",
    form:{
      id:null,
      own_name: "",
      name:"",
      division_id:null,
      root_parent_division_id:null,
      inn: "",
      country_letter:"", 
      region: "",
      city: "",
      street: "",
      house: "",
      room: "",
      postal_index: "",
      branch_name:  "",
      branch_inn: "",
      branch_country_letter: "", 
      branch_region:  "",
      branch_city:  "",
      branch_street:  "",
      branch_house: "",
      branch_room: "",
      branch_postal_index:  "",
    }
  }),
  computed:{
    ...mapGetters({
      countries: "catalogs/getNsiCountries",
      regions: "catalogs/getNsiRegions",
      user:"auth/getUser"
    }),
    userId(){
      return this.user?.user?.id
    },
    isEdit(){
      return !!(this.params.item)
    },
    regionsForSelectedCountry(){
      if(this.form.country_letter){
        const code = getCountryDigitCodeByLetterCode(this.countries,this.form.country_letter)
        return this.regions.filter(i => i.countryDigit === code || i.countryDigit === null)
      }
      return this.regions
    },
    regionsForSelectedBranchCountry(){
      if(this.form.branch_country_letter){
        const code = getCountryDigitCodeByLetterCode(this.countries,this.form.branch_country_letter)
        return this.regions.filter(i => i.countryDigit === code || i.countryDigit === null)
      }
      return this.regions
    },
    countryRegionName() {
      const region =
        this.regions.find(
          (i) => i.code === this.form.region
        ) || {};
      return region?.name ?? "";
    },
    branchCountryRegionName() {
      const region =
        this.regions.find(
          (i) => i.code === this.form.branch_region
        ) || {};
      return region?.name ?? "";
    },
    divisionId(){
      return this.user?.user?.activeDivisionId;
    }
  },
  watch:{
    params:{
      handler(nv){
        if(nv.item){
          this.setFields()
        }
      },
      deep:true,
      immediate:true,
    }
  },
  methods:{
    setFields(){
      const item = this.params.item
      Object.keys(this.form).forEach(key => {
        if(key in item){
          this.form[key] = item[key]
        }
      })
      const {contacts, branch_contacts} = item
      const [contact] = contacts
      const [branch_contact] = branch_contacts
      this.phone = contact?.contact
      this.branch_phone = branch_contact?.contact
    },
    submit(){
      if(this.form.own_name.length < 3){
        return this.$snackbar({text:'Псевдоним должен быть длиннее 3х символов', color:'red', top:false, right: false})
      }
      const payload = {
        ...this.form,
        user_id:this.userId,
        country_name:getCountryNameByCode(this.countries, this.form.country_letter),
        branch_country_name:getCountryNameByCode(this.countries, this.form.branch_country_letter),
        division_id:this.divisionId,
        contacts: [
          {
            code: "TE",
            name: "ТЕЛЕФОН",
            contact:this.phone
          }
        ],
        branch_contacts: [
          {
            code: "TE",
            name: "ТЕЛЕФОН",
            contact:this.branch_phone
          }
        ],
      }
      this.$store.dispatch("catalogs/saveCarrier", payload).then(() => {
        this.$snackbar({
          text: "Справочник обновлен",
          color: "blue",
          top:false, 
          right: false
        });
      }).catch(err => {
        this.$snackbar({text: err || "Ошибка",color:"red", top:false, right: false});
      });
    },
    clear(){
      this.phone = ""
      this.branch_phone= ""
      this.form = {
        id:null,
        own_name: "",
        name:"",
        inn: "",
        country_letter:"", //2
        country_name: "",//2
        region: "",
        city: "",
        street: "",
        house: "",
        room: "",
        postal_index: "",   
        branch_name:  "",
        branch_inn: "",
        branch_country_letter: "", //3
        branch_country_name:  "",//3
        branch_region:  "",
        branch_city:  "",
        branch_street:  "",
        branch_house: "",
        branch_room: "",
        branch_postal_index:  "",
        branch_contacts: [],
      }
    }
  }
}
</script>
<style scoped>
  .form{
    width:100%;
    min-height: calc(100vh - 129px)
  }
</style>